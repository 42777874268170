var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "permission",
            rawName: "v-permission",
            value: "contents.create",
            expression: "'contents.create'",
          },
        ],
        staticClass: "flex flex-wrap-reverse items-center",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
            on: { click: _vm.addNew },
          },
          [
            _c("feather-icon", {
              attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
            }),
            _c("span", { staticClass: "ml-2 text-base text-primary" }, [
              _vm._v(_vm._s(_vm.$t("AddNew"))),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      [
        _c("Grid", {
          attrs: {
            hide_actions: false,
            service: _vm.service,
            route_name: "contents",
            column_formats: {
              type: (val) => _vm.$t(`content_types.${val}`),
              release_at: (val) => _vm.$utils.format.date(val),
            },
            css_formats: {
              type: (val) => (val === "A" ? "text-warning" : "text-success"),
            },
          },
          scopedSlots: _vm._u([
            {
              key: "gridActions",
              fn: function () {
                return [
                  _c("vs-th", { key: "actions" }, [
                    _vm._v(_vm._s(_vm.$t("Actions"))),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function (actions) {
                return [
                  _c("feather-icon", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: `${actions.route_name}.edit`,
                        expression: "`${actions.route_name}.edit`",
                      },
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-left",
                        value: {
                          content: "Alterar conteúdo",
                          delay: { show: 500, hide: 100 },
                        },
                        expression:
                          "{\n            content: 'Alterar conteúdo',\n            delay: { show: 500, hide: 100 }\n          }",
                        modifiers: { "top-left": true },
                      },
                    ],
                    attrs: {
                      icon: "EditIcon",
                      svgClasses:
                        "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                    },
                    on: {
                      click: function ($event) {
                        return actions.edit(actions.data[actions.rowindex].id)
                      },
                    },
                  }),
                  _c("feather-icon", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: `${actions.route_name}.delete`,
                        expression: "`${actions.route_name}.delete`",
                      },
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-left",
                        value: {
                          content: "Excluir conteúdo",
                          delay: { show: 500, hide: 100 },
                        },
                        expression:
                          "{\n            content: 'Excluir conteúdo',\n            delay: { show: 500, hide: 100 }\n          }",
                        modifiers: { "top-left": true },
                      },
                    ],
                    attrs: {
                      icon: "Trash2Icon",
                      svgClasses:
                        "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                    },
                    on: {
                      click: function ($event) {
                        return actions.destroy(actions.data[actions.rowindex])
                      },
                    },
                  }),
                  _c("feather-icon", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-left",
                        value: {
                          content: "Copiar link do conteúdo",
                          delay: { show: 500, hide: 100 },
                        },
                        expression:
                          "{\n            content: 'Copiar link do conteúdo',\n            delay: { show: 500, hide: 100 }\n          }",
                        modifiers: { "top-left": true },
                      },
                    ],
                    ref: `act${actions.rowindex}`,
                    attrs: {
                      icon: "LinkIcon",
                      svgClasses:
                        "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.copyLink(
                          actions.data[actions.rowindex],
                          actions.rowindex,
                          `act${actions.rowindex}`
                        )
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }